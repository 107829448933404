<script setup>
import { ref, watch, onMounted } from 'vue'
import { useAddressStore } from '~/stores/addresses.js'
import BasicAutocompleteInput from '~/components/custom-formkit/BasicAutocompleteInput.vue'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const inputValue = ref(props.context.value)
const selectedAddress = ref(props.context.value && props.context.value['selected_address'])
const fullAddress = ref(props.context.value && props.context.value['full_address'])
const udprn = ref(props.context.value && props.context.value['udprn'])
const uprn = ref(props.context.value && props.context.value['uprn'])
const line_1 = ref(props.context.value && props.context.value['line_1'])
const line_2 = ref(props.context.value && props.context.value['line_2'])
const line_3 = ref(props.context.value && props.context.value['line_3'])
const postcode = ref(props.context.value && props.context.value['postcode'])
const options = ref([])
const disabled = computed(() => props.context.disabled || false)

watch(
  inputValue,
  (newVal) => {
    props.context.node.input(newVal)
  },
  { deep: true },
)

const handleSelect = (item) => {
  if (item) {
    selectedAddress.value = item['address']
    udprn.value = item['udprn']
    updateAddressByUdprn(udprn.value)
    updateInputValue()
  }
}

const handleSelectBlur = () => {
  if (!store.response.some((option) => option.address === selectedAddress.value)) {
    clearInput()
  }
}

const clearInput = () => {
  inputValue.value = ''
  fullAddress.value = ''
  udprn.value = ''
  uprn.value = ''
  line_1.value = ''
  line_2.value = ''
  line_3.value = ''
  postcode.value = ''
}

const amendAddress = () => {
  ;(udprn.value = ''), (uprn.value = ''), updateInputValue()
}

const updateInputValue = () => {
  inputValue.value = {
    ...inputValue.value,
    selected_address: selectedAddress.value,
    udprn: udprn.value,
    uprn: uprn.value,
    line_1: line_1.value,
    line_2: line_2.value,
    line_3: line_3.value,
    postcode: postcode.value,
    full_address: updateFullAddress(),
  }
}

const updateFullAddress = () => {
  let addressParts = [line_1.value, line_2.value, line_3.value, postcode.value]

  fullAddress.value = addressParts.filter((part) => part?.trim() !== '').join(', ')

  return fullAddress.value
}

const store = useAddressStore()

const updateAddressByUdprn = async (udprnSearch) => {
  const address = await store.getAddressesUdprn(udprnSearch)
  if (address) {
    uprn.value = address.uprn
    line_1.value = address.line_1
    line_2.value = address.line_2
    line_3.value = address.line_3
    postcode.value = address.postcode
    updateInputValue()
  }
}

const fetchSuggestions = async (queryString) => {
  await store.fetchAddresses(queryString)
  return store.response
}
</script>

<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-autocomplete
          v-model="selectedAddress"
          :fetch-suggestions="fetchSuggestions"
          placeholder="Type to search address ..."
          class="w-full"
          value-key="address"
          required="required"
          clearable="true"
          :disabled="disabled"
          @select="handleSelect"
          @blur="handleSelectBlur"
        ></el-autocomplete>
      </el-col>
    </el-row>
    <el-row gutter="20">
      <el-col :xs="24" :sm="12" class="mt-4">
        <el-form-item label="Address Line 1" required>
          <el-input v-model="line_1" :disabled="disabled" @change="amendAddress" />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" class="mt-4">
        <el-form-item label="Address Line 2" required>
          <el-input v-model="line_2" :disabled="disabled" @change="amendAddress" />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" class="mt-4">
        <el-form-item label="Address Line 3">
          <el-input v-model="line_3" :disabled="disabled" @change="amendAddress" />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" class="mt-4">
        <el-form-item label="Postcode" required>
          <el-input v-model="postcode" :disabled="disabled" @change="amendAddress" />
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>
